
@font-face {
  font-family: 'Avant Garde';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/avant-garde.woff') format('woff');
}

body {
  margin: 0;
  font-family: Avant Garde,
  Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
